<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
  >
    <b-form-group
      v-bind="$attrs"
      label-size="sm"
      :class="[styled ? 'my-2' : 'my-0']"
    >
      <b-input-group
        class="input-group-merge"
      >
        <template
          v-if="$attrs.prepend"
          #prepend
        >
          <b-input-group-text :class="{ 'input-group-disabled': $attrs.disabled }">
            {{ $attrs.prepend }}
          </b-input-group-text>
        </template>

        <b-form-input
          :id="vid"
          v-model="innerValue"
          :class="{ 'input-group-disabled': $attrs.disabled }"
          v-bind="$attrs"
          @blur="$emit('blur')"
          @keyup.enter="$emit('keyup:enter')"
          @keyup.esc="$emit('keyup:esc')"
        />

        <template
          v-if="$attrs.append"
          #append
        >
          <b-input-group-text :class="{ 'input-group-disabled': $attrs.disabled || $attrs['append-disabled'] }">
            {{ $attrs.append }}
          </b-input-group-text>
        </template>

      </b-input-group>
      <b-alert
        variant="danger"
        :show="errors.length > 0 ? true:null"
        class="mb-0 mt-1"
      >
        <div class="alert-body">
          <small>{{ errors[0] }}</small>
        </div>
      </b-alert>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import {
  BFormGroup, BFormInput, BAlert, BInputGroup, BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BAlert,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupText,
    ValidationProvider,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    // 15/09/2022 - TAG: Must be included in props
    vid: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
    styled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // 15/09/2022 - TAG: Handles internal model changes
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // 15/09/2022 - TAG: Handles external model changes
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>

<style lang="scss" scoped>
  .input-group-disabled {
    background-color:#ededed;
  }
</style>
