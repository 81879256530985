<template>
  <div>
    <ImportsListsFilters
      :title="title"
      @on:filter="handleFilter"
      @on:column-option-change="handleColumnOptionChange"
    />

    <template v-if="isLoadingImports">
      <BasicSkeleton height="150px" />
      <BasicSkeleton height="350px" />
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <vue-good-table
            v-if="availableImports"
            id="reports-table"
            ref="imports-products-table"
            class="vgt-table-checkbox-styled"
            style-class="vgt-table condensed align-text-bottom"
            :columns="columns"
            :rows="formatedImports"
            :group-options="{
              enabled: true,
              headerPosition: 'top'
            }"
            :sort-options="{
              enabled: false,
            }"
          >

            <!-- #region::Empty response -->
            <div slot="emptystate">
              No hay pagos para mostrar.
            </div>
            <!-- #endregion::Empty response -->

            <!-- #region::Table header row -->
            <template
              slot="table-header-row"
              slot-scope="props"
            >
              <div
                v-if="props.column.field === 'SupplierInvoice'"
                class="yellow-row"
              >
                {{ totalSupplierInvoice | currency }}
              </div>
              <div
                v-else-if="props.column.field === 'ImportInvoice'"
                class="yellow-row"
              >
                {{ totalImportInvoice | currency }}
              </div>
              <div
                v-else-if="props.column.field === 'PaymentMns'"
                class="yellow-row"
              >
                {{ totalPaymentMns | currency }}
              </div>
              <div
                v-else
                id="empty-header"
                class="border-none"
              />
            </template>
            <!-- #endregion::Table header row -->

            <!-- #region::Column headers -->
            <template
              slot="table-column"
              slot-scope="props"
            >
              <span>
                <h6 class="capitalize-col-hd">
                  <strong>{{ props.column.label }}</strong>
                </h6>
              </span>
            </template>
            <!-- #endregion::Column headers -->

            <!-- #region::Modifying rows cells -->
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- #region::Custom general cell -->
              <ImportsEditableCell
                :props="props"
                :on-update-row="onUpdateRow"
              />
              <!-- #endregion::Custom general cell -->

            </template>
            <!-- #endregion::Modifying rows cells -->

          </vue-good-table>

          <!-- #region::Alert when no imports are available -->
          <b-alert
            v-if="!availableImports && !isLoadingImports"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron números de importación.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no imports are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableImports"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalImports"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ImportsEditableCell from '@/modules/production/imports/components/ImportsEditableCell.vue'
import ImportsListsFilters from '@/modules/production/imports/components/ImportsListsFilters.vue'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BCard,
    BAlert,
    BCardText,
    VueGoodTable,
    BasicSkeleton,
    BasicPaginator,
    ImportsEditableCell,
    ImportsListsFilters,
  },
  props: {
    /**
     * Titulo que se muestra en la pestaña
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * Enlace base para descargar el archivo CSV
     */
    downloadFileBaseLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      totalImports: 0,
      columns: [
        {
          label: 'No. importación',
          field: 'NoImport',
          inputType: 'text',
          width: '220px',
          required: true,
        },
        {
          label: 'Fecha de llegada',
          field: 'ArriveDate',
          formatFn: this.isValidDate,
          inputType: 'date',
          width: '250px',
          required: true,
        },
        {
          label: 'Disponibilidad',
          field: 'Availability',
          inputType: 'multiselect',
          width: '260px',
          required: true,
        },
        {
          label: 'No. factura',
          field: 'NoInvoice',
          inputType: 'text',
          width: '220px',
          required: true,
        },
        {
          label: 'No. pedimento',
          field: 'NoPetition',
          inputType: 'text',
          width: '220px',
          required: true,
        },
        {
          label: 'Fecha pedido',
          field: 'OrderDate',
          formatFn: this.isValidDate,
          inputType: 'date',
          width: '250px',
        },
        {
          label: 'ETD',
          field: 'Etd',
          formatFn: this.isValidDate,
          inputType: 'date',
          width: '250px',
        },
        {
          label: 'ETA',
          field: 'Eta',
          formatFn: this.isValidDate,
          inputType: 'date',
          width: '250px',
        },
        {
          label: 'Factura proveedor',
          field: 'SupplierInvoice',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
        },
        {
          label: 'Factura importación',
          field: 'ImportInvoice',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
        },
        {
          label: 'Pago desde MNS',
          field: 'PaymentMns',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
        },
        {
          label: 'Pago desde OPTIMUM',
          field: 'PaymentOptimum',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
        },
        {
          label: 'Pago otros',
          field: 'PaymentOthers',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
        },
        {
          label: 'Pendiente de pago',
          field: 'OutstandingPayment',
          formatFn: this.isValidCurrency,
          inputType: 'currency',
          width: '220px',
          canEdit: false,
        },
        {
          label: 'Notas',
          field: 'Notes',
          inputType: 'text',
          width: '420px',
          maxLength: 500,
        },
      ],
      imports: [],
      isLoadingImports: false,

      totalSupplierInvoice: 0,
      totalImportInvoice: 0,
      totalPaymentMns: 0,

      /**
       * Identificadores de los registros para el cálculo de totales
       */
      ids: [],
    }
  },
  computed: {
    ...mapGetters({
      getListsParams: 'imports/getListsParams',
      getIsEditionModeEnabled: 'imports/getIsEditionModeEnabled',
      getIsLoadingGlobalResponse: 'imports/getIsLoadingGlobalResponse',
      getUsedProductsColumnsList: 'imports/getUsedProductsColumnsList',
      /**
       * Filtros globales
       */
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredAvailability: 'filters/getFilteredAvailability',
      getFilteredETDDateRange: 'filters/getFilteredETDDateRange',
      getFilteredETADateRange: 'filters/getFilteredETADateRange',
      getFilteredOrderDateRange: 'filters/getFilteredOrderDateRange',
      getFilteredArrivalDateRange: 'filters/getFilteredArrivalDateRange',
    }),
    /**
     * Listas de parametros de filtrado de pestañas
     */
    listsParams: {
      get() { return this.getListsParams },
    },
    /**
     * Indica si el modo de edición está activado
     */
    isEditionModeEnabled: {
      get() { return this.getIsEditionModeEnabled },
      set(value) { this.setIsEditionModeEnabled(value) },
    },
    /**
     * Indica si se está cargando una respuesta global
     */
    isLoadingGlobalResponse: {
      get() { return this.getIsLoadingGlobalResponse },
      set(value) { this.setIsLoadingGlobalResponse(value) },
    },
    /**
     * Lista de columnas
     */
    columnsList: {
      get() { return this.getUsedProductsColumnsList },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Indica el término de búsqueda (paginado global)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Disponibilidad seleccionada (filtro en store)
     */
    filteredAvailability: {
      get() { return this.getFilteredAvailability },
      set(value) { this.setFilteredAvailability(value) },
    },
    /**
     * Fecha ETD seleccionado (filtro en store)
     */
    filteredETDDateRange: {
      get() { return this.getFilteredETDDateRange },
      set(value) { this.setFilteredETDDateRange(value) },
    },
    /**
     * Fecha ETA seleccionado (filtro en store)
     */
    filteredETADateRange: {
      get() { return this.getFilteredETADateRange },
      set(value) { this.setFilteredETADateRange(value) },
    },
    /**
     * Fecha de pedido seleccionado (filtro en store)
     */
    filteredOrderDateRange: {
      get() { return this.getFilteredOrderDateRange },
      set(value) { this.setFilteredOrderDateRange(value) },
    },
    /**
     * Fecha de llegada seleccionado (filtro en store)
     */
    filteredArrivalDateRange: {
      get() { return this.getFilteredArrivalDateRange },
      set(value) { this.setFilteredArrivalDateRange(value) },
    },
    /**
     * Aplica formato a la estructura de los registros de importaciones
     */
    formatedImports() {
      return [{
        label: 'Importaciones',
        html: false,
        children: this.imports.map(item => Object.assign(item, item.import)),
      }]
    },
    /**
     * Verifica la disponibilidad de los registros de importaciones
     */
    availableImports() {
      return this.totalImports > 0
    },
    isLoadedParamsForList() {
      return this.listsParams.findIndex(params => params.title === this.title) !== -1
    },
  },

  /**
   * Hook que se ejecuta cuando el componente es montado
   * @summary Si la lista ya ha sido cargada previamente, se cargan las columnas
   * que han sido seleccionadas desactivadas
   */
  mounted() {
    if (this.isLoadedParamsForList) {
      const { selectedColumns } = this.getListsParams.find(p => p.title === this.title)
      const hiddenColumns = selectedColumns.filter(c => c.active === false)

      hiddenColumns.forEach(hiddenColumn => {
        this.handleColumnOptionChange(hiddenColumn)
      })
    }
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  * @summary Carga la lista de importaciones
  */
  async created() {
    await this.loadImportsListWithVerification()
  },

  methods: {
    ...mapActions({
      loadImports: 'imports/loadImports',
      loadImportsTotal: 'imports/loadImportsTotal',
      updateImportNumber: 'imports/updateImportNumber',
      setIsEditionModeEnabled: 'imports/setIsEditionModeEnabled',
      setIsLoadingGlobalResponse: 'imports/setIsLoadingGlobalResponse',
      /**
       * Filtros globales
       */
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredAvailability: 'filters/setFilteredAvailability',
      setFilteredETDDateRange: 'filters/setFilteredETDDateRange',
      setFilteredETADateRange: 'filters/setFilteredETADateRange',
      setFilteredOrderDateRange: 'filters/setFilteredOrderDateRange',
      setFilteredArrivalDateRange: 'filters/setFilteredArrivalDateRange',
    }),

    /**
    * Evento de filtrado
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de pagos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'availability':
          this.filteredAvailability = filter.value
          break
        case 'etdDate':
          this.filteredETDDateRange = filter.value
          break
        case 'etaDate':
          this.filteredETADateRange = filter.value
          break
        case 'orderDate':
          this.filteredOrderDateRange = filter.value
          break
        case 'arrivalDate':
          this.filteredArrivalDateRange = filter.value
          break
        default:
          this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadImportsListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadImportsListWithVerification() {
      await this.loadImportsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadImportsList()
    },

    /**
    * Verificación de contenido
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.imports.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableImports) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadImportsList()
        }
      }
    },

    /**
    * Carga de registros de tipo pagos
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de pagos y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de pagos
    */
    async loadImportsList() {
      try {
        this.isLoadingImports = true

        const response = await this.loadImports(this.loadParams())
        this.imports = response.data.data.data
        this.totalImports = response.data.data.total
        this.lastPage = response.data.data.last_page

        // Establece los identificadores de los registros
        this.ids = response.data.ids

        const totalResponse = await this.loadImportsTotal({
          preOwned: 1,
          ids: this.ids,
        })

        this.totalSupplierInvoice = totalResponse.data.TotalSupplierInvoice
        this.totalImportInvoice = totalResponse.data.TotalImportInvoice
        this.totalPaymentMns = totalResponse.data.TotalPaymentMns

        this.isEditionModeEnabled = false
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isLoadingImports = false
      }
    },

    /**
    * Actualización de registros de tipo iportaciones
    * @summary Actualiza los registros de tipo importaciones estableciendo los parámetros
    * y el tipo de importacion. Actualiza los totales de las facturas de proveedor, importación
    * y pagos en MNS
    * @param {Object} row - Registro a actualizar
    */
    async onUpdateRow(row) {
      try {
        const formData = new FormData()
        formData.append('preOwned', 1) // 0 = producto nuevo

        formData.append('noInvoice', row.NoInvoice)
        formData.append('noImportNew', row.NoImport)
        formData.append('arriveDate', row.ArriveDate)
        formData.append('noPetition', row.NoPetition)
        formData.append('availability', row.Availability)
        formData.append('noImport', row.originalImportNumber)

        formData.append('orderDate', row.OrderDate || '')
        formData.append('etd', row.Etd || '')
        formData.append('eta', row.Eta || '')
        formData.append('supplierInvoice', row.SupplierInvoice || '')
        formData.append('importInvoice', row.ImportInvoice || '')
        formData.append('paymentMns', row.PaymentMns || '')
        formData.append('paymentOptimum', row.PaymentOptimum || '')
        formData.append('paymentOthers', row.PaymentOthers || '')
        formData.append('notes', row.Notes || '')

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        this.isLoadingGlobalResponse = true
        const response = await this.updateImportNumber({ formData, loadParams: this.loadParams() })

        const totalResponse = await this.loadImportsTotal({
          preOwned: 1,
          ids: this.ids,
        })

        this.totalSupplierInvoice = totalResponse.data.TotalSupplierInvoice
        this.totalImportInvoice = totalResponse.data.TotalImportInvoice
        this.totalPaymentMns = totalResponse.data.TotalPaymentMns

        this.isLoadingGlobalResponse = false
        this.$set(row, 'OutstandingPayment', response.data.data.OutstandingPayment)

        this.showToast(
          'Actualización',
          'El campo se ha actualizado correctamente',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista. Determina
    * si se muestra solo pagos en efectivo
    */
    loadParams() {
      return {
        preOwned: 1,
        title: this.title,
        perPage: this.filteredPerPage,
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
        availabilityTerm: this.filteredAvailability,
        etdDateStartTerm: this.filteredETDDateRange?.from,
        etdDateEndTerm: this.filteredETDDateRange?.to,
        etaDateStartTerm: this.filteredETADateRange?.from,
        etaDateEndTerm: this.filteredETADateRange?.to,
        orderDateStartTerm: this.filteredOrderDateRange?.from,
        orderDateEndTerm: this.filteredOrderDateRange?.to,
        arrivalDateStartTerm: this.filteredArrivalDateRange?.from,
        arrivalDateEndTerm: this.filteredArrivalDateRange?.to,

        selectedColumns: this.isLoadedParamsForList
          ? this.getListsParams.find(p => p.title === this.title).selectedColumns
          : this.columnsList.map(a => ({ ...a })),
      }
    },

    /**
    * Validacion de datos numéricos
    * @summary Valida si los datos numéricos son válidos y se aplica el formato de moneda
    * @param {String} value - Valor a validar
    * @return {String} Valor con formato de moneda
    */
    isValidCurrency(value) {
      return validateCurrency(value)
    },

    /**
     * Valida si la fecha es válida
     * @summary Valida si la fecha es válida y se aplica el formato de fecha
     * @param {String} value - Valor a validar
     * @return {String} Valor con formato de fecha
     */
    isValidDate(value) {
      return value ? this.$moment(value).format('DD/MM/YYYY') : 'N/A'
    },

    /**
    * Gestionador de cambio de opción de columna
    * @summary Gestiona el cambio de opción de columna. Oculta o muestra la columna
    * @param {Object} column - Objeto de la columna
    */
    handleColumnOptionChange(column) {
      const listColumn = this.columns.find(c => c.field === column.value)
      this.$set(listColumn, 'hidden', !column.active)
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
