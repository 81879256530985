/**
* Validador de totales
* @summary Valida que el valor sea nulo, en caso de serlo devuelve N/A, en caso contrario
* devuelve el valor formateado como moneda
* @param {Number} value - Valor a validar
*/
function validateCurrency(value) {
  if (value === null) {
    return 'N/A'
  }

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return USDollar.format(value)
}

export default validateCurrency
