<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
  >
    <label
      v-if="showLabel"
      for="arrivalDate"
      class="d-flex"
    ><span>{{ $attrs.label }}</span><h5
      v-if="showRequiredDot"
      class="text-danger"
    >
      *
    </h5></label>
    <b-form-datepicker
      :id="vid"
      v-model="innerValue"
      v-bind="$attrs"
      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
      local="es"
      locale="es"
      :boundary="isViewPortContainer ? 'viewport' : 'scrollParent'"
      :state="errors.length > 0 ? false:null"
    />
    <b-alert
      variant="danger"
      :show="errors.length > 0 ? true:null"
      class="mb-0 mt-1"
    >
      <div class="alert-body">
        <small>{{ errors[0] }}</small>
      </div>
    </b-alert>
  </ValidationProvider>
</template>

<script>
import {
  BFormDatepicker, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BFormDatepicker,
    BAlert,
  },
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    // 15/09/2022 - TAG: Must be included in props
    vid: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showRequiredDot: {
      type: Boolean,
      default: false,
    },
    isViewPortContainer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // 15/09/2022 - TAG: Handles internal model changes
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    // 15/09/2022 - TAG: Handles external model changes
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
