<template>
  <ValidationProvider
    ref="validator"
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
  >
    <b-form-group
      id="exampleInputGroup3"
      v-bind="$attrs"
      label-size="sm"
      :class="[styled ? 'my-2' : 'my-0']"
    >
      <v-select
        v-model="innerValue"
        v-bind="$attrs"
        :label="property"
        :options="options"
        :clearable="clearable"
      >
        <template #no-options>
          No se encontró ningún resultado.
        </template>
      </v-select>
      <b-alert
        variant="danger"
        :show="errors.length > 0 ? true:null"
        class="mb-0 mt-1"
      >
        <div class="alert-body">
          <small>{{ errors[0] }}</small>
        </div>
      </b-alert>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { BFormGroup, BAlert } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BAlert,
    vSelect,
  },
  props: {
    vid: {
      type: String,
      required: true,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: [Object, String],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    styled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal, oldVal) {
      this.$emit('input', newVal, oldVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
