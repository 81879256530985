<template>
  <div
    class="d-flex align-content-center align-items-center pb-0 flex-nowrap"
    :class="isCellEditing ? 'justify-content-left' : 'justify-content-between'"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >

    <!-- #region::Cell value -->
    <h6
      v-if="!isCellEditing"
      class="scrollable-content my-25"
    >
      {{ props.formattedRow[props.column.field] }}
    </h6>
    <!-- #endregion::Cell value -->

    <validation-observer
      v-else
      ref="cellForm"
      class="flex-fill"
    >
      <b-form @submit="$event.preventDefault()">

        <!-- #region::Type text cell input -->
        <TextInputWithValidation
          v-if="inputType === 'text'"
          v-model="modelValue"
          vid="modelValue"
          :rules="{
            required: isRequired,
          }"
          type="text"
          :maxlength="maxLength"
          :name="props.column.label"
          :placeholder="props.column.label"
          :styled="false"
          @keyup:enter="onUpdateChanges"
          @keyup:esc="onCancelChanges"
        />
        <!-- #endregion::Type text cell input -->

        <!-- #region::Type multiselect cell input -->
        <SelectWithValidation
          v-if="inputType === 'multiselect'"
          v-model="modelValue"
          vid="modelValue"
          :rules="{
            required: isRequired,
          }"
          property="text"
          :name="props.column.label"
          :placeholder="props.column.label"
          :options="getAvailabilities"
          :styled="false"
        />
        <!-- #endregion::Type multiselect cell input -->

        <!-- #region::Type date cell input -->
        <DatePickerWithValidation
          v-if="inputType === 'date'"
          v-model="modelValue"
          vid="modelValue"
          :rules="{
            required: isRequired,
          }"
          :name="props.column.label"
          :placeholder="props.column.label"
          :is-view-port-container="true"
        />
        <!-- #endregion::Type date cell input -->

        <!-- #region begin::Currency cell input -->
        <TextInputWithValidation
          v-if="inputType === 'currency'"
          v-model="modelValue"
          vid="modelValue"
          :rules="{
            required: isRequired,
            decimal,
          }"
          type="text"
          maxlength="12"
          prepend="$"
          placeholder="100.00"
          :name="props.column.label"
          :styled="false"
          @keyup:enter="onUpdateChanges"
          @keyup:esc="onCancelChanges"
        />
        <!-- #endregion end::Currency cell input -->

      </b-form>
    </validation-observer>

    <!-- #region::Edit cell button -->
    <b-button
      v-if="isHovered && !isEditionModeEnabled && canEdit && !isLoadingLocalResponse && !isLoadingGlobalResponse"
      v-b-tooltip.hover.top="'Editar'"
      variant="flat-secondary"
      class="btn-icon rounded-circle p-25"
      @click="onCellEditingActivated"
    >
      <feather-icon icon="Edit3Icon" />
    </b-button>
    <!-- #endregion::Edit cell button -->

    <!-- #region::Update cell value button -->
    <b-button
      v-if="isCellEditing"
      v-b-tooltip.hover.top="'Aceptar'"
      variant="flat-success"
      class="btn-icon rounded-circle p-25 align-self-start mt-50 mx-25"
      @click="onUpdateChanges"
    >
      <feather-icon icon="CheckIcon" />
    </b-button>
    <!-- #endregion::Update cell value button -->

    <!-- #region::Cancel cell changes button -->
    <b-button
      v-if="isCellEditing"
      v-b-tooltip.hover.top="'Cancelar'"
      variant="flat-danger"
      class="btn-icon rounded-circle p-25 align-self-start mt-50"
      @click="onCancelChanges"
    >
      <feather-icon icon="XIcon" />
    </b-button>
    <!-- #endregion::Cancel cell changes button -->

    <b-spinner
      v-if="isLoadingLocalResponse && isLoadingGlobalResponse"
      class="mr-50"
      small
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required, alphaNum } from '@validations'
import {
  ValidationObserver, extend, localize, decimal,
} from 'vee-validate'
import {
  BButton, VBTooltip, BForm, BSpinner,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'

export default {
  components: {
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    props: {
      type: Object,
      required: true,
    },
    onUpdateRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
      isCellEditing: false,
      isLoadingLocalResponse: false,

      modelValue: '',

      /**
       * Validations
       */
      alphaNum,
      required,
      decimal,
    }
  },
  computed: {
    ...mapGetters({
      getAvailabilities: 'pieces/getAvailabilities',
      getIsEditionModeEnabled: 'imports/getIsEditionModeEnabled',
      getIsLoadingGlobalResponse: 'imports/getIsLoadingGlobalResponse',
    }),
    /**
     * Disponibilidades de las piezas
     */
    availabilities: {
      get() { return this.getAvailabilities },
    },
    /**
     * Indica si el modo de edición está activado
     */
    isEditionModeEnabled: {
      get() { return this.getIsEditionModeEnabled },
      set(value) { this.setIsEditionModeEnabled(value) },
    },
    /**
     * Indica si se está cargando una respuesta global
     */
    isLoadingGlobalResponse: {
      get() { return this.getIsLoadingGlobalResponse },
    },
    /**
     * Indica el tipo de dato de la celda
     */
    inputType() {
      return this.props.column.inputType
    },
    /**
     * Indica si la celda es editable
     */
    canEdit() {
      return this.props.column.canEdit ?? true
    },
    /**
     * Indica si la celda es requerida
     */
    isRequired() {
      return this.props.column.required ?? false
    },
    /**
     * Indica la longitud máxima de la celda
     */
    maxLength() {
      return this.props.column.maxLength ?? 250
    },
    /**
     * Información de los registros sin formato
     */
    originalRow() {
      return this.props.row
    },
    /**
     * Nombre del campo
     */
    field() {
      return this.props.column.field
    },
    /**
     * Indica si el tipo de celda es multiselect
     */
    isMultiselectCellType() {
      return this.inputType === 'multiselect'
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  * @summary Verifica si el tipo de celda es multiselect, de ser así, asigna el valor
  * al modelo de la celda con un objeto que contiene el texto, de lo contrario, asigna
  * el valor al modelo de la celda con el valor original de la celda. Establece el idioma
  * y extiende las reglas de validación
  */
  created() {
    if (this.isMultiselectCellType) {
      this.modelValue = { text: this.originalRow[this.field] }
    } else {
      this.modelValue = this.originalRow[this.field]
    }

    localize('es')
    extend('alpha_num', {
      ...alphaNum,
      message: 'El campo solo debe contender letras y números',
    })
  },
  methods: {
    ...mapActions({
      setIsEditionModeEnabled: 'imports/setIsEditionModeEnabled',
    }),
    onCellEditingActivated() {
      this.isCellEditing = true
      this.isEditionModeEnabled = true
    },
    async onUpdateChanges() {
      const success = await this.$refs.cellForm.validate()

      if (success) {
        this.isCellEditing = false
        this.isEditionModeEnabled = false

        const originalImportNumber = this.originalRow.NoImport

        this.originalRow[this.field] = this.isMultiselectCellType
          ? this.modelValue.text
          : this.modelValue

        this.isLoadingLocalResponse = true
        await this.onUpdateRow(Object.assign(this.originalRow, { originalImportNumber }))
        this.isLoadingLocalResponse = false
      }
    },
    onCancelChanges() {
      this.modelValue = this.originalRow[this.field]
      this.isCellEditing = false
      this.isEditionModeEnabled = false
    },
  },
}
</script>

<style lang="scss" scoped>
.scrollable-content {
  max-height: 20px;
  overflow-y: scroll;
}
.scrollable-content::-webkit-scrollbar {
  display: none;
}
</style>
