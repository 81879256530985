<template>
  <div>
    <b-skeleton
      class="mt-1"
      animation="wave"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  components: {
    BSkeleton,
  },
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100px',
    },
  },
}
</script>
